@import '../../../scss/settings';

.content {
  padding: 12px;
  width: 100%;
}

.widthBtn {
  width: fit-content !important;
}

.elipse {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 225px;
}

.discardBTn {
  width: fit-content !important;
  border: none !important;
  background-color: transparent !important;
  color: $tertiary-color-500 !important;
}

.inputWidth {
  width: 29vw;
}

.locationBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthDropdown {
  width: 45vw;
}

.leftMenuBox {
  border-radius: 10px;
  height: 67px;
  width: 270px;
  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 12px;
  border: 1px solid $light-color;
}

.currentTabText {
  color: $primary-color !important;
}

.industryWidth {
  width: 33vw;
}

.currentTab {
  @extend .leftMenuBox;
  background: $primary-color-50;
}

//.modal{
//  padding: 10px;
//  width: 100vw;
//  margin-top: 30px;
//}
.modalBtn {
  width: fit-content !important;
}

.dotIcon {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  background-color: $light-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 60px;
    top: 23px;
    border-right: 2px dashed $disabled-color;
  }
}

.segmentItem {
  display: none;
  align-items: center;

  &:last-child {
    .dotIcon::after {
      display: none;
    }
  }
}

.orgLeftSection {
  &:last-child {
    .dotIcon::after {
      display: none;
    }

    .stepIcon::after {
      display: none;
    }
  }
}

.mobileStepper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.mobileMenu {
  display: flex;
  justify-content: space-between;
}

.stepIcon {
  border-radius: 50%;
  background: $light-color;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    z-index: -1;
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 25vw;
    margin-left: 100px;
    //transform: rotate(90deg);
    border-bottom: 2px dashed $disabled-color;
  }
}

.leftSide {
  border-right: 1px solid $light-color;
}

.rightSide {
  width: 100%;
}

.iconContainer {
  &:last-child {
    .stepIcon::after {
      display: none;
    }
  }
}

.contentQuestion {
  border-bottom: 2px solid $light-color;
  padding: 40px 0;
  width: 100%;
  //justify-content: space-between;
}

.contentQuestionSkill {
  @extend .contentQuestion;
  border: none !important;
}

.heightModal {
  height: 100%;
}

.job {
  width: 41vw;
}

.widthLeft {
  color: $gray-color;
  font-weight: 600 !important;
}

.fontColor {
  color: $black !important;
  border: 1px solid $primary-color !important;
  width: 190px !important;
  height: 38px;
}

.innerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.whiteBtn {
  display: none !important;
  background: none !important;
  color: $tertiary-color-500 !important;
  font-weight: 400;
  border: none !important;
  width: fit-content !important;
}

.outlineBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
}

.outerContent {
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  padding-bottom: 40px;
  width: 100%;
}

.educationCard {
  border-radius: 10px;
  border: 1px solid $border-color;
  padding: 24px 32px;
  margin-top: 24px;
  width: 100%;
}

.widthEdit {
  width: 70px;
}

.educationCardWithoutImport {
  @extend .educationCard;
  width: 385px;
}

.resumeUploadBox {
  padding: 24px 32px;
  border-radius: 10px;
  height: fit-content;
  border: 1px solid $border-color;
}

.borderBottom {
  border-bottom: 2px solid $gray-200-color;
  padding-bottom: 16px;
}

.borderBottomLight {
  padding-bottom: 16px;
  border-bottom: 2px solid $light-color;
}

.selectedResume {
  border: 1px solid $primary-color-200;
  border-radius: 10px;
  background: $primary-color-50;
  padding: 16px;
  margin-top: 16px;
}

.link {
  color: $primary-color;
  cursor: pointer;
}

.borderTop {
  border-top: 1px solid $primary-color;
  padding-top: 10px;
}

.infoBox {
  border-radius: 10px;
  background: $info-50-color;
  padding: 12px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.devider::before {
  border-left: 1px solid $border-color;
}

.answerLength {
  width: 100%;
  margin-top: 15px;
}

.loadingContent {
  border-radius: 10px;
  padding: 24px;
  border: 1px solid $gray-200-color;
}

.timeDuration {
  width: 100%;
}

.heightDivider {
  height: 45%;
  width: 0;
  border: 1px solid $neutralColor;
}

.heightDivideBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.webStepper {
  display: none;
}

.mobileStepper {
  margin-top: 40px;
  margin-bottom: 30px;
}

.webHeader {
  display: none;
}

.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  display: flex;
  width: fit-content;
  align-items: center;
  margin-top: 7px;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  //padding-top: 20px;
  //margin-top: 20px;
  //border-top: 2px solid $light-color;
}

.tagBox {
  width: 100%;
  //display: flex;
}

.tagDelete {
  margin-left: 10px;
}

@include respondTo($md) {
  .stepIcon {
    &::after {
      margin-left: 215px;
    }
  }
}

@include respondTo($lg) {
  .monthYear {
    display: flex;
  }

  .yearDropdown {
    width: 20vw;
  }
  //.modal{
  //  display: block;
  //  height: 350px;
  //  //padding: 40px 60px 40px 60px;
  //  //width: 700px;
  //  //margin-top: 0;
  // overflow-x: hidden;
  //  overflow-y: auto;
  //}

  .rightSide {
    padding: 0 20px;
  }
  .content {
    padding: 20px;
  }
  .whiteBtn {
    display: flex !important;
  }
  .locationAnswer {
    display: flex;
  }
  .locationBox {
    flex-direction: row;
  }
  .locationQuestion {
    width: 75%;
  }
  .timeDuration {
    width: 50%;
  }
  .monthDropdownModal {
    width: 21vw;
  }
}

@include respondTo($xl) {
  .searchBar {
    width: 100%;
  }
  .bottomContent {
    flex-direction: row;
  }
  .addButtonFixWidth {
    width: fit-content !important;
    margin-left: 10px;
  }
  .mobileMenu {
    flex-direction: column;
  }
  .monthDropdown {
    width: 15vw;
  }
  .widthRight {
    width: 585px;
  }
  .job {
    width: 17vw;
  }
  .resumeContainer {
    display: flex;
    justify-content: space-between;
  }
  .uploadBox {
    width: 50%;
  }
  .mobileStepper {
    display: none;
  }
  .webStepper {
    display: block;
  }
  .webHeader {
    display: flex;
    flex-wrap: wrap;
  }

  .mobHeader {
    display: none;
  }
  .widthUpload {
    width: 350px;
  }
  .noticeperiodWidth {
    width: 36vw;
  }
  .dropdownWidth {
    width: 100%;
  }
  .locationSearchWidth {
    width: 33vw;
  }
  .Resumecontent {
    display: flex;
    justify-content: space-between;
  }
  .segmentItem {
    display: flex;
  }
  .leftSide {
    padding-right: 20px;
    width: 43%;
  }
  .outerContent {
    display: flex;
  }
  .listDropDown {
    border-radius: 10px !important;
  }
  .contentQuestion {
    display: flex;
  }
  .answerLength {
    margin-top: 0;
    width: 60%;
  }
  .leftSide {
    width: 23%;
  }
  .widthLeft {
    width: 40%;
  }
  .content {
    display: flex;
    padding: 70px;
  }
  .educationCard {
    width: 585px;
  }
  .educationCardWithoutImport {
    width: unset;
  }
  //.job{
  //  width: 305px;
  //}
}

@include respondTo($xxl) {
  .job {
    width: 20vw;
  }
}

.error {
  color: $danger-color;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 1.4rem;
  line-height: 1;
}

.visaDropdown {
  width: 230px;
}

.locationDropdown {
  width: 50%;
}

.borderClass {
  border-bottom: 2px solid $light-color;
  padding-bottom: 20px;
}

.rightToWorkBox {
  // left: 100px;
  width: 50%;
}

.yupError {
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 0;
  color: #cd2e00;
  // margin-left: 5px;
  margin-top: 3px;
  font-size: 1.4rem;
  line-height: 1;
}

.loc datalist {
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 350px;
  padding: 5px;
  max-height: 10rem;
  overflow-y: auto;
}

.loc option {
  background-color: white;
  padding: 4px;
  color: blue;
  margin-bottom: 1px;
  font-size: 18px;
  cursor: pointer;
}

.loc option:hover,
.active {
  background-color: lightblue;
}

.nonOutlinedButton {
  background: none !important;
  color: $almost-black !important;
  width: fit-content;
  border: none !important;
}

.widthButton {
  width: fit-content;
}

// style for textarea to have rounded corners and whitish border and to have a placeholder
.textareaStyle {
  border-radius: 10px !important;
  border: 1px solid $gray-200-color;
  padding: 10px;
  font-size: 1.4rem;
  color: $almost-black;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  width: 100%;
  height: 100px;
  resize: none;
  outline: none;

  &::placeholder {
    color: $gray-200-color;
  }

  &:focus {
    border: 1px solid $primary-color;
  }
}

.width100 {
  width: 100%;
}
