@import 'src/scss/settings';

.modal {
  padding: 24px;
}

.modalProfile {
  padding: 24px;
  width: 800px;
}

.dropdownWidth {
  width: 142px;
}
.dropdownWidthFilter {
  width: 195px;
}
.borderTop {
  border-top: 1px solid $gray-200-color;
  padding-top: 10px;
}

.cancelBtn,
.searchBtn {
  width: fit-content !important;
}

.tag {
  padding: 4px 7px;
  align-items: center;
  border-radius: 40px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  background-color: $primary-color-50;
}

.imageCircle {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.discardBtn {
  width: fit-content !important;
  border: 1px solid $tertiary-color-500 !important;
  background: transparent !important;
  color: $tertiary-color-500 !important;
}

.cancelBtn {
  background: transparent !important;
  border: 2px solid $primary-color-100 !important;
  color: $tertiary-color-500 !important;
}

.yupError {
  top: 20px;
  bottom: 0;
  left: 0;
  color: #cd2e00;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 1.2rem;
  line-height: 1;
}

.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  margin-left: 24px;
}

w250 {
  width: 250px !important;
}

@include respondTo($md) {
  .dropdownWidthFilter {
    width: 280px;
  }
  .levelDropDown {
    width: 200px;
  }
  .dropdownTeam {
    width: 400px;
  }
  .dropdownWidth {
    width: 270px;
  }
  .responsive {
    display: flex;
  }
}
