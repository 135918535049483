@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Black'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Black.woff2') format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Black.woff') format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Black.ttf') format('truetype');
  //src: url("../fonts/poppins/Poppins-Black.eot?#iefix") format("embedded-opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-BlackItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.woff2')
      format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.woff')
      format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.ttf')
      format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-BoldItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.woff2')
      format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.woff') format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Bold'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Bold.woff2') format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Bold.woff') format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-ExtraLight'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.woff2')
      format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.woff') format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf')
      format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-ExtraLightItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.woff2')
      format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.woff')
      format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf')
      format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Italic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Italic.woff2') format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Italic.woff') format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Light'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Light.woff2') format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Light.woff') format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-LightItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-LightItalic.woff2')
      format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-LightItalic.woff')
      format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-LightItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-Regular'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Regular.woff2') format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Regular.woff') format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-SemiBold'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.woff') format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source_Sans_Pro';
  src:
    local('SourceSansPro-SemiBoldItalic'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.woff2')
      format('woff2'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.woff')
      format('woff'),
    url('../fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Black'),
    url('../fonts/Montserrat/static/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-BlackItalic'),
    url('../fonts/Montserrat/static/Montserrat-BlackItalic.ttf')
      format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Bold'),
    url('../fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-BoldItalic'),
    url('../fonts/Montserrat/static/Montserrat-BoldItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ExtraBold'),
    url('../fonts/Montserrat/static/Montserrat-ExtraBold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ExtraBoldItalic'),
    url('../fonts/Montserrat/static/Montserrat-ExtraBoldItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ExtraLight'),
    url('../fonts/Montserrat/static/Montserrat-ExtraLight.ttf')
      format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ExtraLightItalic'),
    url('../fonts/Montserrat/static/Montserrat-ExtraLightItalic.ttf')
      format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Italic'),
    url('../fonts/Montserrat/static/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Light'),
    url('../fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Medium'),
    url('../fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-LightItalic'),
    url('../fonts/Montserrat/static/Montserrat-LightItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-MediumItalic'),
    url('../fonts/Montserrat/static/Montserrat-MediumItalic.ttf')
      format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Regular'),
    url('../fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-SemiBold'),
    url('../fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-SemiBoldItalic'),
    url('../fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-Thin'),
    url('../fonts/Montserrat/static/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat-ThinItalic'),
    url('../fonts/Montserrat/static/Montserrat-ThinItalic.ttf')
      format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
