@import '../../../scss/settings';

.candidatesListCard {
  border: 1px solid $gray-200-color;
  border-radius: 10px;
  padding: 24px 20px;
}

.filterBtn {
  padding: 6px 14px;
  border: 1px solid $primary-color-50;
  width: 185px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.transparentBtn {
  width: fit-content !important;
  background: transparent !important;
  color: $tertiary-color-500 !important;
  border: none !important;
}

.widthFilter {
  background-color: transparent !important;
  color: $black !important;
  border: none !important;
  width: 10px !important;
}

.borderBottom {
  border-bottom: 1px solid $gray-200-color;
  padding-bottom: 25px;
}

.rightSide {
  margin-top: 10px;
  width: 100%;
}

.rightInner {
  margin-top: 10px;
}

// style for textarea to have rounded corners and whitish border and to have a placeholder
.textareaStyle {
  border-radius: 10px !important;
  border: 1px solid $gray-200-color;
  padding: 10px;
  font-size: 1.4rem;
  color: $almost-black;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  width: 100%;
  height: 100px;
  resize: none;
  outline: none;

  &::placeholder {
    color: $gray-200-color;
  }

  &:focus {
    border: 1px solid $primary-color;
  }
}

.roundTag {
  border-radius: 20px;
  padding: 4px 16px;
  border: 2px solid $primary-color-100;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inactiveTag {
  background: $danger-color;
  color: $white;
  border-radius: 20px;
  padding: 4px 16px;
  margin-left: 24px;
  height: fit-content !important;
}

.tagInactiveStyle {
  padding: 0px 7px;
  align-items: center;
  border-radius: 40px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  color: $white;
  background: $danger-color;
  margin-left: 10px;
}

.tagUnsuccessfulStyle {
  padding: 0px 7px;
  align-items: center;
  border-radius: 40px;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  color: $white;
  background: $gray-300-color;
  margin-left: 10px;
}

.disabled {
  @extend .roundTag;
  cursor: not-allowed;
  border: 2px solid $border-color !important;
}

.tabSelected {
  @extend .roundTag;
  background: $primary-color;
  color: $white !important;
}

.discardBTn {
  background: transparent !important;
  border: none !important;
  color: $tertiary-color-500;
  width: fit-content !important;
}

.textArea {
  border: 1px solid $gray-200-color;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  height: 100px;
}

.fontSize {
  font-size: 16px;
}

.contentBox {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  margin-top: 40px;
  padding: 12px;
}

.drawer {
  /* In commentaries are the way to make it slide from left to right */

  background: black;

  top: 0;
  right: 100%;
  /* right: 100%; */

  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);

  transform: translateX(100%);
  /* transform: translateX(0%); */
}

.draweropen {
  z-index: 200;
  width: 50%;
  display: block;
  transform: translateX(0%);
  position: absolute;
  height: 100%;
  transition: transform 0.3s ease-out;
  /* transform: translateX(100%); */
}

.noBorder {
  border: none;
  outline: none;
}

.saveBtn {
  width: fit-content !important;
}

.bottomContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: end;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 2px solid $light-color;
}

.skillsCard {
  border: 1px solid $gray-200-color;
  border-radius: 10px;
  padding: 12px;
  margin-top: 24px;
  width: 100%;
}

.listDot {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: $primary-color;
}

.nonOutlinedButton {
  background: none !important;
  color: $almost-black !important;
  width: fit-content;
  border: none !important;
}

.width100 {
  width: 100%;
}

.updateDropdown {
  width: 100%;
}

.textAreaCatchUp {
  @extend .textArea;
  font-size: 16px;
  height: 186px;
  width: 100%;
}

.assessmentResult {
  border-radius: 10px;
  border: 1px solid $success-color;
  background: $success-color-50;
  padding: 24px 32px;
}

.dateTime {
  padding: 8px 16px !important;
  border-radius: 8px !important;
  border: 1px solid $primary-color;
  font-size: 16px !important;
  width: 350px !important;
}

.resumeUploadBox {
  padding: 24px 32px;
  border-radius: 10px;
  height: fit-content;
  border: 1px solid $border-color;
}

.selectedResume {
  border: 1px solid $primary-color-200;
  border-radius: 10px;
  background: $primary-color-50;
  padding: 16px;
  margin-top: 16px;
}

.card {
  border-radius: 10px;
  border: 1px solid $gray-color;
  padding: 16px;
  background: $white;
}

.greenCard {
  @extend .card;
  border: 1px solid $success-color;
  background: $success-color-50;
}

.supplyBtn {
  border: 1px solid $primary-color-100 !important;
  background: transparent !important;
  color: $black !important;
}

.orangeCard {
  @extend .card;
  border: 1px solid $orange-color;
  background: $orange-color-50;
}

.rate {
  border-radius: 10px;
  padding: 8px 20px;
  width: 23rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $gray-color;
}

.negotiateRate {
  @extend .rate;
  border: 1px solid $success-color;
}

.counterOfferRate {
  @extend .rate;
  border: 1px solid $warning-color;
}

.borderLeft {
  border-left: 2px solid $almost-black;
}

.borderLeftGreen {
  border-left: 2px solid $success-color-500;
}

.borderLeftOrange {
  border-left: 2px solid $orange-color;
}

.borderTop {
  border-top: 1px solid $primary-color;
  padding-top: 10px;
}

.average {
  @extend .assessmentResult;
  padding: 8px 20px;
  width: 100% !important;
}

.averageNew {
  border-radius: 10px;
  border: 1px solid black;
  padding: 8px 20px;
  width: 100% !important;
  height: fit-content;
}

.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.educationCard {
  border-radius: 10px;
  border: 1px solid $border-color;
  padding: 32px 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  //width: 585px;
}

.borderRight {
  border-right: 1px solid $gray-200-color;
  padding-right: 10px;
}

.w15 {
  width: 15% !important;
}

.selectedResumeFull {
  border: 1px solid $primary-color-200;
  border-radius: 10px;
  background: $primary-color-50;
  padding: 16px;
  margin-top: 16px;
  cursor: pointer;
}

.outlinedBtn {
  width: fit-content !important;
  background-color: $primary-color-50 !important;
  color: $black !important;
  border: 2px solid $primary-color !important;
}

.elipse {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 185px;
}

.greenBorderBottom {
  border-bottom: 2px solid $success-color;
  //padding-bottom: 10px;
}

.roundTag {
  border-radius: 20px;
  padding: 4px 16px;
  border: 2px solid $primary-color-100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerRoundTab {
  @extend .roundTag;
  border: 1px solid $gray-color-100;
}

.borderDarkBottom {
  border-bottom: 2px solid $gray-color;
}

.image {
  border-radius: 10px;
  height: 128px;
  width: 128px;
  object-fit: cover;
}

.addSkillBtn {
  background: transparent !important;
  color: $tertiary-color-500 !important;
}

.infoContent {
  padding: 12px;
}

//.tabWidth {
//  display: none;
//}

.resultBox {
  display: flex;
  flex-direction: column-reverse;
}

.mobileView {
  display: none;
}

.displayResponsive {
  display: none !important;
}

.removePadding {
  padding: 5px !important;
}

.mobileStepper {
  border: 2px solid $primary-color;
  padding: 16px;
  background: $primary-color-50;
  border-radius: 8px;
}

.stepperInside {
  padding: 10px;
}

.status {
  border-radius: 10px;
  background: $success-color-50;
  padding: 4px 16px;
  height: fit-content;
  width: fit-content;
}

.borderClass {
  display: flex;
}

@include respondTo($md) {
  .widthMenu {
    width: 60vw;
  }
  .updateDropdown {
    width: 150px;
  }
  .borderClass {
    border-left: 1px solid $gray-200-color;
    border-right: 1px solid $gray-200-color;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
  }
  .widthSearch {
    width: 327px;
  }
  .mobileStepper {
    display: none;
  }
  //.candidateCard {
  //  padding: 20px;
  //}

  .statusBorder {
    border-left: 1px solid $gray-color-100;
    padding-left: 10px;
    margin-left: 10px;
  }
  .supplyBtn {
    width: fit-content !important;
  }
  .widthBtn {
    width: fit-content !important;
  }
  .contentBox {
    padding: 24px 32px 40px 32px;
  }
  .skillsCard {
    padding: 24px 32px;
  }
  .assessmentResult {
    width: 340px;
  }
  .displayResponsive {
    display: flex !important;
  }
}

@include respondTo($lg) {
  .responsive {
    display: flex;
  }
}

@include respondTo($xl) {
  .content {
    display: flex;
  }
  .infoContent {
    display: flex;
    flex-direction: column;
    padding: 70px;
  }
  .leftContent {
    width: 40%;
  }
  .tabWidth {
    display: flex;
    width: 90% !important;
  }
  .tabBox {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid $primary-color-100;
    border-radius: 10px;
  }
  .tab {
    padding: 4px 16px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selectedTab {
    @extend .tab;
    border-radius: 10px;
    border: 1px solid $primary-color;
    background-color: $primary-color-50;
  }
  .leftSide {
    width: 40%;
    margin-right: 60px;
  }
  .resultBox {
    display: flex;
    justify-content: space-between;
  }
  .textArea {
    height: 260px;
    width: 29vw;
  }
  .rightSide {
    margin-top: 0;
    margin-left: 40px;
  }
  .rightInner {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
  }
  .mobileView {
    display: flex;
  }
}
