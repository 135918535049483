@import './../../../scss/settings';

.imageUpload {
  height: 150px;
  width: 150px;
  border-radius: 10px;
  position: relative;
}

.imageSize {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 10px;
}

.uploadIcon {
  position: absolute;
  left: 5%;
  height: 48px;
  top: 64%;
  width: 48px;
  border-radius: 50%;
  background: $primary-color-50;
  display: flex;
  justify-content: center;
  border: 5px solid $primary-color;
  align-items: center;
}
