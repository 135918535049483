@import './../../../scss/settings';

.deleteButton {
  width: fit-content;
  background: $danger-color !important;
  border: none !important;
}

.cancelButton {
  width: fit-content;
  border: 2px solid $border-color;
  background: transparent !important;
  color: black;

  &:hover {
    color: black;
  }
}

.addBtn {
  color: $white !important;
  border: 1px solid $primary-color !important;
  width: fit-content !important;
  height: 38px;
}
