@import '../node_modules/bootstrap/scss/bootstrap';
@import '../src/scss/settings';
@import '../src/scss/fonts';
@import '../src/scss/calender';

html {
  font-size: 62.5%;
}

body {
  padding: 0;
  margin: 0;
  font-family:
    Source_Sans_Pro,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif !important;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

/** .common classes */
.nav-space {
  padding-top: 112px;
}

.pointer-events-none {
  pointer-events: none;
  cursor: default;
}

.text-info-color {
  color: $info-color !important;
}

.pointer {
  cursor: pointer;
}

.max-width {
  max-width: 1920px;
}

.w-70 {
  width: 70% !important;
}

.title-margin {
  margin-bottom: 20px;
}

.custom-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: inherit;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}

/** .end common classes */

/** typography */
.text-bolder {
  font-weight: 700 !important;
}

.text-bold {
  font-weight: 600 !important;
}

.text-semi-bold {
  font-weight: 500 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-thin {
  font-weight: 200 !important;
}

.darkgray-text {
  color: $text-dark-color !important;
}

.text-almostBlack {
  color: $almost-black !important;
}

.info-text {
  color: $info-color !important;
}

.light-text {
  color: $text-light-color !important;
}

.black-text {
  color: $black !important;
}

.text-gray-light {
  color: #6b7280 !important;
}

.white-text {
  color: $white !important;
}

.text-warning {
  color: $warning-color !important;
}

.text-orange {
  color: $orange-color !important;
}

.primary-color-text {
  color: $primary-color !important;
}

.secondary-color-text {
  color: $secondary-color !important;
}

.gray-color-text {
  color: $gray-color;
}

.text-success-color {
  color: $success-color !important;
}

.text-success-500 {
  color: $success-color-500 !important;
}

.placeHolder-color {
  color: $gray-300-color !important;
}

.text-tertiary-color {
  color: $tertiary-color-500 !important;
}

.text-gray-600 {
  color: $gray-600-color !important;
}

.text-sans {
  font-family: 'Source_Sans_Pro' !important;
}

.text-montserrat {
  font-family: 'Montserrat' !important;
}

.font-size-extra-small {
  font-size: 0.9rem !important;
}

.font-size-small {
  font-size: 1rem !important;
}

.font-size-default {
  font-size: 1.2rem !important;
}

.font-size-semi {
  font-size: 1.4rem !important;
}

.font-size-large {
  font-size: 1.6rem !important;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.signInLineHeight {
  line-height: 25.6px !important;
}

.uppercase {
  text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h4,
h5 {
  font-family: 'Montserrat';
  font-weight: 600 !important;
  color: $black;
  line-height: 1;
  margin: 0 !important;
  margin-bottom: 0 !important;
}

h6,
p {
  font-family: Source_Sans_Pro;
  font-weight: 600 !important;
  margin-bottom: 0 !important;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.8rem;
}

h5 {
  font-size: 1.6rem;
}

h6 {
  font-size: 1.4rem;
}

p {
  font-size: 1rem;
  font-weight: 400;
  //line-height: 1;
  color: $text-dark-color;
  margin: 0;
}

.textarea.form-control {
  font-size: 16px !important;
}

.form-control {
  font-size: 16px !important;
}

.ReactTags {
  -webkit-appearance: none;
}

.ReactTags__tagInputField {
  border: 1px solid $gray-200-color !important;
  -webkit-tap-highlight-color: $white;
  width: 100%;
  border-radius: 10px;
  height: 38px;
  padding: 5px;

  &:focus-visible {
    box-shadow: 0 0 0 1px rgb($primary-color, 1);
    border: none !important;
    outline: none !important;
  }
}

.ReactTags__tag {
  background: $primary-color-50;
  padding: 5px;
  margin: 5px;
  border-radius: 10px;
}

.ReactTags__suggestions {
  margin-top: 20px !important;
  background: white !important;

  ul {
    list-style-type: none !important;
  }
}

.ReactTags__activeSuggestion {
  cursor: pointer;
  border-radius: 10px;
  border-color: $primary-color;
  background: $primary-color;
  transition: color 0.2s ease-in;
  color: $white !important;

  &:hover {
    background: $primary-color;
    transition: color 0.2s ease-in;
    color: $white !important;
  }

  & + .ReactTags__suggestions {
    border-top-color: transparent;
  }
}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  ::before {
    content: attr(title);
  }
}

.ReactTags__remove {
  background: transparent;
  border: none;
  box-shadow: none;
}

/** .end typography */

/** .responsive changes */
@include respondTo($md) {
  .common-page {
    &--content-area {
      width: 90%;

      &--big {
        width: 90%;
      }

      &--bigger {
        width: 95%;
      }
    }
  }
}

/** pdf page related styles */
.pdf-page {
  display: flex;
  width: 100%;
  justify-content: center;
  min-height: 100vh;

  &--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &--doc {
    width: 100%;
  }

  &--page-num {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }
}

/** .end */

/** form fields styles */
.custom-check-box {
  width: 1em;
  height: 1em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  border-radius: 3px !important;
  float: left;
  cursor: pointer;

  &:hover {
    background-color: rgba($primary-color, 0.25);
    box-shadow: 0 0 2px 3px rgb($primary-color, 0.25);
  }

  &:focus {
    background-color: rgba($primary-color, 0.25);
    box-shadow: 0 0 2px 3px rgb($primary-color, 0.25);
  }

  &:checked {
    border-color: $secondary-color;
    background-color: $secondary-color;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
}

.custom-check-box-checked {
  border-color: $secondary-color;
  background-color: $secondary-color;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.custom-check-box-disabled {
  pointer-events: none !important;
}

/** .end */
.form-check-input:checked {
  background-color: $primary-color !important;
  border: 1px solid #c3e5e9 !important;
}

.form-check-input:checked[type='radio'] {
  background-image: url('images/radioDot.png') !important;
}

.form-check-input {
  padding: 5px;
  margin-right: 5px;
}

.absolute-center {
  position: absolute;
  text-align: center;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form-check {
  display: flex !important;
  align-items: center !important;
}

@include respondTo($lg) {
  .nav-space {
    padding-top: 0;
  }

  .common-page {
    &--content-area {
      width: 70%;

      &--big {
        width: 90%;
      }
    }
  }

  .title-margin {
    margin-bottom: 40px;
  }

  h1 {
    font-size: 3.8rem !important;
  }

  h2 {
    font-size: 2.4rem !important;
  }

  h3 {
    font-size: 2.2rem !important;
  }

  h4 {
    font-size: 2rem !important;
  }

  h5 {
    font-size: 1.8rem !important;
  }

  h6 {
    font-size: 1.6rem !important;
  }

  p {
    font-size: 1.2rem;
    font-weight: 400;
    color: $text-dark-color;
  }
}
