
.candidateFilter {
  position: relative;
  width: 350px;
  margin-bottom: 16px;

  @media screen and (max-width: 991px){
    width: 100%;
  }
}

.searchInput {
  width: 100%;
  padding: 8px 32px 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border: #00adbc 2px solid;
  }
}

.searchInputWrapper {
  position: relative;
}

.dropdownList {
  position: absolute;
  z-index: 1000;
  width: 100%;
  margin-top: 4px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;

  .dropdownItemName {
    font-size: 14px;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 2px;
  }

  .dropdownItem {
    padding: 8px 12px;
    cursor: pointer;
    font-size: medium;

    &:not(:first-child) {
      border-top: #ccc 1px solid;
    }

    &:hover {
      background-color: #f3f4f6;
    }
  }

  .dropdownItemDetails {
    font-size: 12px;
    color: #6b7280;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
