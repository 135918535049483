@import './../../scss/settings';

.card {
  border-radius: 10px;
  padding: 24px 32px;
  border: 1px solid $gray-200-color;
  height: fit-content;
  margin-bottom: 100px;
  background: $white;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.closeBtn {
  width: fit-content !important;
  color: $tertiary-color-500 !important;
  background: transparent !important;
  border: none !important;
}

.assessmentClock {
  left: 90%;
  top: 135px;
}

.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  width: fit-content;
}

.inactiveTag {
  background: $danger-color;
  color: $white;
  border-radius: 20px;
  padding: 4px 16px;
  width: fit-content;
}

.widthButton {
  width: fit-content;
}

.whiteBtn {
  background: none !important;
  color: $tertiary-color-500 !important;
  font-weight: 400;
  border: none !important;
  width: fit-content;
  height: 20px;
}

.assessmentAnswers {
  border-radius: 50% !important;
}

.content {
  display: flex;
  justify-content: center;
  padding: 12px;
}

.borderBottom {
  border-bottom: 1px solid $gray-200-color;
  padding-bottom: 25px;
}

.borderTop {
  border-top: 1px solid $light-color;
  padding-top: 25px;
}

.OutlinedBtn {
  color: $text-dark-color !important;
  border: 3px solid $primary-color-50 !important;
  background: none !important;
  width: fit-content !important;
}

.borderClass {
  border-left: 1px solid $gray-200-color;
  border-right: 1px solid $gray-200-color;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.borderRight {
  border-right: 1px solid $gray-200-color;
  display: flex;
  padding-right: 15px;
}

.w34 {
  width: 34%;
}

.w33 {
  width: 33%;
}

.w95 {
  width: 95rem;
}

.w75 {
  width: 75%;
}

.w32 {
  width: 32%;
}

.w14 {
  width: 14%;
}

.paddingRight {
  padding-right: 100px;
}

.marginTop {
  margin-top: 30px;
}

.marginLeft {
  margin-left: 117px;
}

.contentAssessment {
  padding: 12px;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.answerLength {
  width: 100%;
  margin-top: 15px;
}

.jobExperienceText {
  display: block;
  margin-top: 15px;
  gap: 5px;
  width: 100%;
  line-height: 25px;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: textfield !important;
  appearance: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border-radius: 8px;
  color: $text-dark-color;
  font-size: 1.6rem;
  padding: 8px 16px;
  border: 1px solid $gray-200-color;
  box-shadow: none;
  font-weight: 400;

  &::placeholder {
    opacity: 0;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    color: $text-dark-color;
    box-shadow: 0 0 0 1px rgb($primary-color, 1);
  }
}

.ellipse {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //width: 100%;
}

.ellipseResume {
  @extend .ellipse;
  width: 40%;
}

.link {
  color: $primary-color;
  cursor: pointer;
  width: 12%;

  &:focus {
    color: $primary-color;
  }
}

.linkCandidate {
  @extend .link;
  width: unset;
}

.clickedLink {
  color: $text-light-color;
  cursor: none;
  width: 12%;
}

@include respondTo($md) {
  .responsive {
    display: flex;
    justify-content: space-between;
  }
  .subResponsive {
    display: flex;
    justify-content: end;
    width: 35%;
  }
}

@include respondTo($lg) {
  .assessmentClock {
    position: fixed;
  }
  .content {
    margin-top: 70px;
  }
  .contentAssessment {
    padding: 60px 270px 100px 260px;
  }
  //.modal{
  //  padding: 60px;
  //  width: 700px;
  //}
  .card {
    width: 50%;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  top: 0;
  right: 0;
  display: block;
}

.closeBackdrop {
  display: none;
}

.textNote {
  width: 100%;
  height: 300px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid $gray-300-color;
}

.saveBtn {
  width: fit-content !important;
}

.card {
  border-radius: 10px;
  border: 1px solid $gray-300-color;
  padding: 15px 12px;
  margin-bottom: 10px;
}

.addSkillBtn {
  background: transparent !important;
  color: $tertiary-color-500 !important;
}

.borderBtn {
  height: 38px;
  background-color: $primary-color-50 !important;
  color: $black !important;
  border: 2px solid $primary-color !important;
}

.linkButton {
  border: 2px solid $gray-200-color;
  height: 40px;
  width: 100%;
}

.pdfButton {
  border-radius: 10px;
  font-size: 16px !important;
  color: $almost-black !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-underline: none !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  padding: 10px;
}
