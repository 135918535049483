@import '../../../scss/settings';

.card {
  border-radius: 10px;
  border: 1px solid $light-color;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.selectedCard {
  border-radius: 10px;
  border: 1px solid $primary-color;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.image {
  width: 80px;
  border-radius: 10px 0 0 10px;
  height: 100px;
  object-fit: cover;
}

.cardInner {
  padding: 10px 18px 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.rateMark {
  border-radius: 10px;
  border: 1px solid $success-color;
  background: $success-color-50;
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
}
