@import 'src/scss/settings';

.image {
  object-fit: cover;
  height: 100vh;
  width: 100%;
  position: relative;
}

.modal {
  padding: 12px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImage {
  height: 75px;
}

.logo {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageorg {
  @extend .image;
  height: 125vh;
}

.imageContainer {
  display: none;
}

.whiteBtn {
  background: none !important;
  color: $tertiary-color-500 !important;
  font-weight: 400;
  border: none !important;
  width: fit-content;
  height: 20px;
  padding: 0 !important;
}

.devidedLine {
  width: 45%;
  border: 1px solid $neutralColor;
}

.leftContentBox {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.middleContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.divider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 10px;

  &::after {
    content: '';
    border-bottom: 1px solid $gray-200-color;
    position: absolute;
    width: 100%;
    display: block;
  }
}

.fontColor {
  color: $gray-300-color;
}

.outlineBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
}

.error {
  color: $danger-color;
  margin-left: 5px;
  margin-top: 3px;
  font-size: 1.2rem;
  line-height: 1;
}

.borderBottom {
  border-bottom: 2px solid $light-color;
  padding-bottom: 16px;
}

.formContainer {
  width: 100%;
  height: fit-content;
}

.widthBtn {
  width: fit-content !important;
}

.w75 {
  width: 75% !important;
}

@include respondTo($lg) {
  .modal {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@include respondTo($xl) {
  .modal {
    padding: 24px 36px;
    width: 700px;
  }
  .logo {
    justify-content: start;
    padding: 20px 0 0 120px;
  }
  .leftContentBox {
    //height: 100vh;
    padding: 30px 120px 52px 120px;
  }
  .formContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }
  .imageContainer {
    display: flex;
    width: 50%;
  }
}
