@import '../../scss/settings';

.content {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.dropdownWidth {
  width: 300px;
}

.linkButton {
  border: 2px solid $gray-200-color;
  height: 30px;
  width: 100%;
}

.pdfButton {
  border-radius: 10px;
  font-size: 16px !important;
  color: $almost-black !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-underline: none !important;
  text-decoration: none !important;
  font-weight: 600 !important;
}

.pdfOPlink {
  height: 30px;
  width: 195px;
  display: flex;
  text-decoration: none !important;
  background: $primary-color;
  color: $white;
  text-align: center;
  display: block;
  border-radius: 50px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  padding: 10px 30px;
  border-radius: 15px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: 3px solid #2aaae1;
  background-color: #2aaae1;
  color: #ffffff;
}

.borderBottom {
  border-bottom: 2px solid $light-color;
  padding-bottom: 28px;
}

.borderBottomTitle {
  border-bottom: 1px solid $gray-200-color;
  padding-bottom: 25px;
}

.borderBtn {
  height: 38px;
  background-color: $primary-color-50 !important;
  color: $black !important;
  border: 2px solid $primary-color !important;
}

.contentBox {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  margin-top: 40px;
  padding: 24px 32px 40px 32px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.expandable {
  width: 100%;
  border: 1px solid $gray-200-color;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.organizationsFilter {
  border-radius: 10px;
  border: 1px solid $gray-200-color;
  //margin-top: 40px;
  padding: 24px 20px;
  height: fit-content;
  overflow-x: hidden;
  overflow-y: auto;
}

.teams {
  @extend .organizationsFilter;
  width: 100% !important;

  border: none;
}

.educationCard {
  border-radius: 10px;
  border: 1px solid $border-color;
  padding: 32px 24px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  //width: 585px;
}

.elipse {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 185px;
}

.orgSelection {
  border-radius: 10px;
  border: 1px solid $gray-200-color;

  &:focus {
    border: 1px solid $almost-black; // set the border color when the div is selected
  }

  &:hover {
    border: 1px solid $almost-black;
  }

  //border-left: none !important;
  margin-top: 40px;
  padding: 10px 18px 10px 12px;
}

.CandidateSelection {
  @extend .orgSelection;
  padding: 0 !important;
}

.statusUpdate {
  border-radius: 10px;
  background: $light-color;
  padding: 12px;
  margin-top: 16px;
}

.image {
  border-radius: 10px;
  height: 145px;
  width: 128px;
  object-fit: cover;
}
.questionWidth {
  width: 100%;
}
.cancelBtn {
  width: fit-content !important;
  background: transparent !important;
  border: 2px solid $primary-color-100 !important;
  color: $black !important;
}

.settingsSelection {
  @extend .orgSelection;
  border-right: none !important;
  border-top: none !important;
  border-left: none !important;
  border-radius: 10px 10px 0px 0px;
  margin-top: 0px !important;
  padding: 12px 32px;
  border: 1px solid $gray-200-color;

  &:focus {
    background-color: $primary-color-50;
    border: 2px solid $primary-color; // set the border color when the div is selected
    color: $almost-black;
  }

  &:hover {
    background-color: $primary-color-50;
    border: 2px solid $primary-color;
    color: $almost-black;
  }

  cursor: pointer;
}

.settingsSelected {
  background-color: $primary-color-50;
  border: 2px solid $primary-color;
  color: $almost-black;
}

.category {
  border-radius: 10px;
  padding: 4px 16px;
  background: $info-50-color;
}

.settingsTitle {
  color: $gray-300-color;
  text-align: center;

  &:hover {
    color: $almost-black;
  }

  &:focus {
    color: $almost-black;
  }
}

.settingsTitleSelected {
  color: $almost-black;
}

.selected {
  border: 1px solid $almost-black; // set the border color when the div is selected
}

.contentQuestion {
  border-bottom: 2px solid $light-color;
  padding: 40px 0;
  width: 100%;
  //justify-content: space-between;
}

.widthLeft {
  color: $gray-color;
  font-weight: 600 !important;
}

.answerLength {
  width: 100%;
  margin-top: 15px;
}

.borderBottomNone {
  border-bottom: none !important;
}

.whiteBtn {
  display: none !important;
  background: none !important;
  color: $tertiary-color-500 !important;
  font-weight: 400;
  border: none !important;
  width: fit-content;
}

.outlineBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
}

.addBtn {
  color: $black !important;
  border: 1px solid $primary-color !important;
  width: fit-content !important;
  height: 38px;
}

.Okbtn {
  color: $white !important;
  border: 1px solid $primary-color !important;
  width: fit-content !important;
  height: 38px;
}

.addQuestionBtn {
  @extend .addBtn;
  background: $primary-color-50 !important;
  width: fit-content !important;
}

.btn {
  border-radius: 20px;
  padding: 4px 16px;
  border: 1px solid $primary-color-100;
  background: $white;
  margin-right: 10px;

  &:hover {
    background: $primary-color-50;
    font-weight: 600 !important;
    border: none;
  }
}

.activeBtn {
  @extend .btn;
  border: 1px solid $primary-color;
  background: $primary-color-50;
  font-weight: 600 !important;
}

.searchBtn {
  @extend .btn;
  height: 50px;
  border-radius: 0px 20px 20px 0px;
  margin-right: 0;
}

.card {
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  border: 1px solid;
  height: fit-content;
  background: $white;
}

.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  margin-left: 24px;
  width: fit-content;
}

.profilePic {
  width: 100px;
  height: 100px;
  position: relative;
  object-fit: cover;
  border-radius: 10px;
  overflow: hidden;
}

.desWidth {
  width: 80%;
}

.table {
  border: 2px solid $primary-color-200;
  width: 100%;
}

.head {
  padding: 15px;
  border-bottom: 2px solid $primary-color-200;
}

.tableContent {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.responsive {
  width: 100%;
}

.borderClass {
  display: flex;
}
.iconBox {
  width: 5%;
}
@include respondTo($md) {
  .iconBox {
    width: fit-content;
  }
  .updateDropdown {
    width: 190px;
  }

  .questionWidth {
    width: 77%;
  }
  .width600 {
    width: 600px !important;
  }
  .categoryWidth {
    width: 25%;
  }
  .searchWidth {
    width: 38vw;
  }
  .borderClass {
    border-left: 1px solid $gray-200-color;
    border-right: 1px solid $gray-200-color;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
  }
  .linkButton {
    width: 195px;
  }
  .rightSideCandidates {
    width: 65%;
  }
  .responsive {
    display: flex;
    justify-content: space-between;
  }
  .rightWidth {
    width: 64%;
  }
  .searchBar {
    width: 20vw;
  }
  .teams {
    padding: 70px;
  }
  .desWidth {
    width: 100%;
  }
  .organizationsFilter {
    width: 35rem;
  }
  .stepIcon {
    &::after {
      margin-left: 215px;
    }
  }
}

@include respondTo($lg) {
  .whiteBtn {
    display: flex !important;
  }
  .rightSide {
    padding: 0 20px;
  }
  .content {
    padding: 20px;
  }
}

@include respondTo($xl) {
  .widthRight {
    width: 585px;
  }
  .leftSide {
    padding-right: 20px;
    width: 43%;
  }
  .outerContent {
    display: flex;
  }
  .contentQuestion {
    display: flex;
  }
  .answerLength {
    margin-top: 0;
    width: 60%;
  }
  .leftSide {
    width: 23%;
  }
  .widthLeft {
    width: 40%;
  }
  .content {
    display: flex;
    padding: 70px;
    flex-direction: row;
  }
  .addBtn {
    width: fit-content !important;
  }
  .card {
    padding: 24px 32px;
  }
}

@include respondTo($xxl) {
  .job {
    width: 20vw;
  }
}
