@import '../../../scss/settings';

.card {
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  border: 1px solid;
  height: fit-content;
  background: $white;
}

.disableFont {
  color: $gray-300-color !important;
}

.infoBox {
  border-radius: 10px;
  background: $info-50-color;
  padding: 12px;
  display: flex;
  align-items: center;
  //margin-top: 16px;
}

.errorBox {
  border-radius: 10px;
  background: $danger-color-50;
  padding: 12px;
  display: flex;
  align-items: center;
  //margin-top: 16px;
}

.discription {
  display: flex;
  flex-direction: column;
}

.rotateArrow {
  transform: rotate(180deg);
  transition-duration: 1s;
  transition-property: transform;
}

.rotateBack {
  transition-duration: 1s;
  transition-property: transform;
}

.blueTag {
  width: fit-content;
  padding: 4px 16px;
  border-radius: 8px;
  display: flex;
  background: $info-50-color;
}

.redTag {
  display: flex;
  background: $danger-color-50;
  width: fit-content;
  padding: 4px 16px;
  border-radius: 8px;
}

.modal {
  padding: 60px;
  width: 700px;
}

.borderBottom {
  border-bottom: 1px solid $gray-200-color;
  padding-bottom: 25px;
}

.widthButton {
  width: fit-content;
}

.OutlinedBtn {
  color: $text-dark-color !important;
  border: 3px solid $primary-color-50 !important;
  background: none !important;
  width: fit-content !important;
}

.nonOutlinedButton {
  background: none !important;
  color: $almost-black !important;
  width: fit-content;
  border: none !important;
}

.Loadingcard {
  border: 2px solid $gray-200-color;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px;
}

.greenBox {
  border-radius: 10px;
  border: 1px solid $success-color;
  display: flex;
  background: $success-color-50;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
}

.yellowBox {
  border-radius: 10px;
  background: $orange-color-50;
  display: flex;
  justify-content: space-between;
  border: 1px solid $warning-color;
  padding: 24px 32px;
}

.resourcePic {
  height: 100px;
  width: 100px;
  border-radius: 16px;
}

.outlineBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
  width: 250px !important;
}

.disabledBtn {
  @extend .outlineBtn;
  background: $light-color !important;
}

.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  margin-left: 24px;
}

.inactiveTag {
  background: $danger-color;
  color: $white;
  border-radius: 20px;
  padding: 4px 16px;
  margin-left: 24px;
  height: fit-content;
}

.outlineProgressBtn {
  @extend .outlineBtn;
  width: 100% !important;
}

.widthBtn {
  width: 250px !important;
}

.viewBtn {
  width: 160px !important;
}

.displayBlock {
  display: block;
  transition-duration: 1s;
  transition-property: display;
}

.displayNone {
  display: none;
  transition-duration: 1s;
  transition-property: display;
}

.profileStatus {
  background: $success-color-50;
  border-radius: 10px;
  padding: 16px 20px;
  transition: padding-top 1s;
}

.openProfileStatus {
  @extend .profileStatus;
  padding: 5px 20px 16px 20px;
  transition: padding-top 1s;
}

.profilePic {
  width: 62px;
  height: 62px;
  position: relative;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

.link {
  color: $primary-color;
  cursor: pointer;
}

.nonPublished {
  padding: 4px 16px;
  border-radius: 10px;
  background: $orange-color-50;
  display: flex;
  align-items: center;
}

.whiteBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
  width: fit-content;
}

.publishedCard {
  @extend .nonPublished;
  background: $success-color-50;
}

.borderClass {
  display: flex;
}

@include respondTo($md) {
  .costInputWidth {
    width: 12vw;
  }
}

@include respondTo($xl) {
  .insideCard {
    display: flex;
    align-items: center;
  }
  .discription {
    flex-direction: row;
    align-items: end;
  }
  .card {
    padding: 24px 32px;
  }
}

.stepBox {
  background-color: $light-color;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 165px;
  height: 50px;
}

.approveBtn {
  background-color: $primary-color-50 !important;
  color: $black !important;
}

.approveDisableBtn {
  @extend .approveBtn;
  color: $gray-300-color !important;
}

.infoBox {
  border-radius: 10px;
  background: $info-50-color;
  padding: 12px;
  display: flex;
  align-items: center;
  //margin-top: 16px;
}

.discription {
  display: flex;
  flex-direction: column;

  background: none !important;
  width: fit-content !important;
}

.nonOutlinedButton {
  background: none !important;
  color: $almost-black !important;
  width: fit-content;
  border: none !important;
}

.Loadingcard {
  border: 1px solid $gray-200-color;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px;
}

.greenBox {
  border-radius: 10px;
  border: 1px solid $success-color;

  background: $success-color-50;
}

@include respondTo($md) {
  .stepBox {
    height: unset;
    padding: 6px 16px;
  }
  .approveBtn {
    width: fit-content !important;
  }
  .responsive {
    display: flex;
  }
  .borderClass {
    border-left: 1px solid $gray-200-color;
    border-right: 1px solid $gray-200-color;

    padding-left: 15px;
    padding-right: 15px;
  }
}

@include respondTo($xl) {
  .insideCard {
    display: flex;
    align-items: center;
  }
  .discription {
    flex-direction: row;
    align-items: end;
  }
}
