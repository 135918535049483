@import './../../../scss/settings';

.GroupBy {
  background: $secondary-color;
  width: 90vw;
  padding: 20px;
  border-radius: 10px;
  margin: 5px;
}

.customDropdown {
  display: flex;
  position: relative;
  max-width: 100%;

  &Content {
    position: relative;
    width: 100%;
  }

  .fieldContainer {
    width: 100%;
    position: relative;
    cursor: pointer;
  }

  .buttonField {
    background-color: $white;
    border: none;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }

  .buttonFieldCurrency {
    @extend .buttonField;
    padding: 8px 5px;
    border: 1px solid $border-color;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inputDisplay {
    background-color: $white;
    border: none;
  }

  .inputDisplayNone {
    background-color: $white;
    border: none;
  }

  .formLabel {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    position: absolute;
    top: 50%;
    transition: all 0.4s ease-out;
    transform: translate(0, -50%);
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 400;
    color: $text-light-color;
  }

  .labelSpaceStartDefault {
    left: 15px !important;
  }

  .labelSpaceStart {
    left: 20px !important;
  }

  .labelSpaceStartWithIconDefault {
    left: 35px !important;
  }

  .labelSpaceStartWithIcon {
    left: 55px !important;
  }

  .labelSpaceEndWithIconDefault {
    padding-right: 35px !important;
  }

  .labelSpaceEndWithIcon {
    padding-right: 55px !important;
  }

  .customField {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: $black;
    font-size: 1.4rem;
    padding: 10px 20px;
    box-shadow: none;
    font-weight: 400;
    box-sizing: border-box;
    background-color: $white;
    text-transform: capitalize;

    &::placeholder {
      opacity: 0;
      cursor: pointer;
    }

    &:read-only {
      pointer-events: none;
      cursor: default;
    }

    &:focus {
      outline: none;
      color: $black;
      border-color: $text-dark-color;
    }
  }

  .customFieldDefault {
    @extend .customField;
    line-height: 1.5;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 10px;
    color: $text-dark-color;
    text-align: center;
    padding: 5px 15px;
    margin-right: 5px;
    border: none;
    box-shadow: none;

    &::placeholder {
      opacity: 0;
      cursor: pointer;
    }

    &:read-only {
      pointer-events: none;
      cursor: default;
    }

    &:focus,
    &:focus-visible {
      outline: none;
      color: $text-dark-color;
      border: none;
    }
  }

  .customField:focus ~ .formLabel,
  .customField:not(:placeholder-shown) ~ .formLabel {
    top: 18px;
    font-size: 1.2rem;
  }

  .customFieldDefault:focus ~ .formLabel,
  .customFieldDefault:not(:placeholder-shown) ~ .formLabel {
    top: 10px;
    font-size: 1rem;
  }

  .customField.iconSpaceStart {
    padding-left: 55px !important;
  }

  .customField.iconSpaceEnd {
    padding-right: 55px !important;
  }

  .customFieldDefault.iconSpaceStart {
    padding-left: 35px !important;
  }

  .customFieldDefault.iconSpaceEnd {
    padding-right: 35px !important;
    white-space: nowrap;
    width: 150px;
  }

  .icon {
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }

  .icon.iconStart {
    left: 20px;
  }

  .icon.iconEnd {
    right: 20px;
  }

  .iconDefault.iconStart {
    left: 15px !important;
  }

  .iconDefault.iconEnd {
    right: 15px !important;
  }

  &Body {
    top: 75px;
    position: absolute;
    width: 100vw;
    left: 0;
    // left: 5px;
    right: 0;
    margin: 0 0;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: $white;
    border-radius: 8px;
    opacity: 1;
    z-index: 1001;
    padding: 5px 10px 20px 10px;
    transition: all 500ms ease-out;

    &InnerBox {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &CurrencyBody {
      // top: 75px;
      z-index: 1001;
      position: absolute;
      width: max-content;
      // left: 0;
      left: 5px;
      right: 0;
      margin: 0 0;
      // min-height: 100vh;
      overflow-x: hidden;
      overflow-y: scroll;
      // background-color: $white;
      border-radius: 8px;
      opacity: 1;
      //z-index: 4;
      padding: 5px 10px 20px 10px;
      transition: all 500ms ease-out;
    }

    &--mobile-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding: 10px;
      border: 1px solid $light-color;

      h1 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 800;
        margin: 0;
        color: $light-color;
      }
    }

    //.selected {
    //  border-radius: 10px;
    //  border-color: $primary-color;
    //  background-color: $primary-color;
    //  color: $white;
    //
    //  & + .customDropdownBodyItem {
    //    border-top-color: transparent;
    //  }
    //}

    .mobile-input {
      padding: 10px 5px;
      margin-left: 0;
      border: 1px solid $light-color;
      box-shadow: 0 3px 8px $light-color;
    }

    &Item {
      display: flex;
      flex-direction: row;
      color: $black;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
      padding: 15px 15px;
      //border-top: 1px solid $border-color;

      span {
        width: 100%;
        display: block;
        transition: all 0.2s ease-in;
      }

      &:hover {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        border-radius: 10px;
        text-align: center;
        //border-color: red;
        background-color: $primary-color;
        color: $white;
        width: 100%;

        & + .customDropdownBodyItem {
          border-top-color: transparent;
        }
      }

      &Currency {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        color: $black;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
        padding: 5px;
        border: 2px solid $border-color;
        border-radius: 10px;
        height: 40px;
        width: 100px;
        margin: 5px;
        box-sizing: border-box;

        &:hover {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          cursor: pointer;
          border-radius: 10px;
          text-align: center;
          background-color: $primary-color;
          border: 2px solid $primary-color;
          color: $white;
          height: 40px;
          width: 100px;
          font-weight: 600;
        }
      }

      &:first-child {
        border-top: none;
      }

      //&:last-child {
      //  border-bottom: 1px solid $border-color;
      //}
    }
  }

  &BodyDefault {
    @extend .customDropdownBody;
  }

  &CurrencyBodyDefault {
    // top: 75px;
    position: absolute;
    width: max-content;
    left: -65px;
    //  left: 5px;
    right: 0;
    margin: 0 0;
    // min-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    //overflow-y: scroll;
    background-color: $white;
    border-radius: 8px;
    opacity: 1;
    z-index: 4;
    padding: 10px 13px 20px 13px;
    transition: all 500ms ease-out;
    border: 1px solid $border-color;
  }
}

.open {
  position: fixed;
  height: 100vh;
  width: 100vw;

  top: 0;
  left: 0;
  // position: relative;
  background-color: $white;
  z-index: 7;

  .customDropdownContent {
    padding: 10px;
    height: auto;
  }
}

.openCurrency {
  position: relative;
  background-color: $white;
  width: 100%;
  z-index: 7;

  .customDropdownContent {
    // padding: 10px;
    height: auto;
  }
}

.closed {
  position: relative;
  height: auto;
  width: 100%;
}

.removeGutter {
  .customDropdownBody {
    margin: 0 0 !important;
    padding: 5px 20px 20px 20px !important;
  }
}

.end {
  margin-top: 4px;
  color: $danger-color;
}

.endBox {
  border-bottom: none !important;
  background: $danger-color-50;
  border-radius: 8px;

  &:hover {
    background: darken($danger-color-50, 5);
  }
}

.flag {
  width: fit-content;
  border-right: 1px solid $border-color;
  padding-right: 5px;
}

.selectedStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: $black;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  padding: 5px;
  border: 2px solid $primary-color;
  border-radius: 10px;
  height: 40px;
  width: 100px;
  margin: 5px;
  box-sizing: border-box;
  background-color: $primary-color;
  border-radius: 10px;
  color: $white;
  font-weight: 600;
}

/** responsive styles */
@include respondTo($md) {
  .customDropdownCurrencyBodyDefault {
    left: -110px;
  }

  .customDropdown {
    &--mobile-title {
      display: none;
    }

    &Body {
      top: 100%;
      position: absolute;
      margin: 0 20px;
      width: max-content;
      max-height: 300px;
      min-height: 0;
      overflow-x: hidden;
      overflow-y: auto;
      z-index: 1001;
      padding: 5px 30px 20px 30px;
      border: 1px solid $border-color;

      &InnerBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        height: 100%;
      }
    }

    &BodyDefault {
      // min-width: 170px;
      margin: 0;
      top: calc(100% + 10px);
      padding: 5px 10px 10px 10px;
      left: -55px;

      .customDropdownBodyItem {
        width: 150px;
        font-size: 1.2rem;
        text-align: center;
        line-height: 1.4rem;
        padding: 10px;
        border-bottom: 1px solid $light-color;
      }
    }
  }
  .customDropdown::after {
    content: ' ';
    position: absolute;
    bottom: -35%; /* At the top of the tooltip */
    left: 35%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  .open {
    position: relative;
    height: auto;
    width: 100%;
    z-index: unset;

    .customDropdownContent {
      padding: 0;
    }
  }

  .buttonFieldCurrency {
    padding: 10px 3px;
  }
}

.webLan {
  display: none;
}

@include respondTo($lg) {
  .customDropdownCurrencyBodyDefault {
    left: 1px;
  }

  .flag {
    padding-right: 20px;
  }

  .mobileLan {
    display: none;
  }

  .webLan {
    display: inline;
  }

  .custom-dropdown {
    &--input {
      margin-left: -5px;
    }
  }
}
