@import '../../../scss/settings';

.tabBox {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 14px;
  width: 100%;

  &Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    border-radius: 20px;
    width: 100%;
  }

  &Header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $border-color;

    &Tab {
      position: relative;
      display: flex;
      flex: 1;
      padding: 6px 10px;
      cursor: pointer;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 5px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span,
      div {
        font-weight: 400;
        font-size: 16px !important;
        color: $text-dark-color;
      }
    }

    &Active {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: $primary-color-50;
      //border: 1px solid $border-color;
      border-bottom: none;
      box-shadow: none;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      span,
      div {
        font-weight: 600;
      }
    }
  }

  .underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 15%;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: $primary-color;
  }
}

.showShadow {
  box-shadow: 0 0 20px -5px rgba($black, 0.2);
}

//@include respondTo($md) {
//  .tabBox {
//    &Header {
//      width: 40%;
//    }
//  }
//}

.notificationCircle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $lightBlue; /* Change this to your desired background color */
  color: $light-primary-color !important; /* Change this to your desired text color */
  font-size: 12px;
  font-weight: bold;
  padding: 2px;
}

.notificationCount {
  color: $light-primary-color !important; /* Change this to your desired text color */
}

@include respondTo($lg) {
  .tabBox {
    &Active {
      border: none;
      box-shadow: 0 -7px 18px -10px rgba($black, 0.2);
    }
  }

  .tabButtonBorder {
    //border: 1px solid $border-color;
    box-shadow: 0 -7px 18px -10px rgba($black, 0.2);
    border-radius: 10px 10px 0 0;
    border-bottom: none;
    padding: 10px;
    margin: 0 5px;
  }
}

/* Add appropriate styling for disabled tabs */
.disabledTab {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 8px;
  opacity: 0.5;
  margin-left: 20px;
  border-radius: 14px;
  width: 15%;
}
