@import './../../../scss/settings';

.customModal {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
  display: flex;

  &:focus-visible {
    outline: none;
    border: none;
  }

  &:focus {
    outline: none;
    border: none;
  }
}

.modal {
  padding: 10px;
  width: 100vw;
  margin-top: 30px;
  //height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.customWidth {
  max-width: 100% !important;
}

.outlineBtn {
  background: none !important;
  border: 2px solid $tertiary-color-100 !important;
  color: $almost-black !important;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($tertiary-color-500, 0.5);
  z-index: 99999;
}

.modalButton {
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //top: 20px;
  //right: 20px;
  border-radius: 50%;
  background-color: $white;
  width: 30px;
  height: 30px;
}

@include respondTo($md) {
  .modal {
    justify-content: center;
  }
  .customModal {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
    border-radius: 14px;
  }

  //.modalButton {
  //  top: 10px;
  //  right: 15px;
  //  box-shadow: none;
  //}
}

@include respondTo($lg) {
  .customModal {
    max-width: 80%;
  }

  .left-82 {
    left: 82px;
  }
}

@include respondTo($xl) {
  .modal {
    display: block;
    padding: 40px 60px 40px 60px;
    width: 700px;
    margin-top: 0;
    //overflow-x: hidden;
    //overflow-y: auto;
  }
  .customModal {
    max-width: 70%;
  }
}
