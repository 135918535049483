@import '../../scss/settings';

.content {
  padding: 12px;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.jobCount {
  background: $danger-color;
  border-radius: 35px;
  height: 29px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterBtn {
  z-index: 2;
  display: none;
  border-radius: 50%;
  background-color: $primary-color-50;
  border: 2px solid $primary-color;
  padding: 10px;
  cursor: pointer;
}

.transparentBtn {
  width: fit-content !important;
  background: transparent !important;
  color: $tertiary-color-500 !important;
  border: none !important;
}

.contentCompany {
  @extend .content;
  padding: 12px;
}

.card {
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  border: 1px solid $gray-200-color;
  height: fit-content;
  background: $white;
}

.greenCard {
  border-radius: 10px;
  border: 1px solid $success-color;
}

.filterDropDown {
  width: 175px;
}

.payableTag {
  background: $primary-color-50;
  border-radius: 20px;
  padding: 4px 16px;
  margin-left: 24px;
}

.bordergreen {
  border-right: 1px solid $success-color-500;
  border-left: 1px solid $success-color-500;
  padding: 0 10px;
}

.bordergray {
  @extend .bordergreen;
  border-right: 1px solid $gray-color;
  border-left: 1px solid $gray-color;
}

.innerGreenCard {
  border-radius: 10px 10px 0 0;
  padding: 24px 20px;
  background-color: $success-color-50;
  border-bottom: 1px solid $success-color;
}

.innerCard {
  border-radius: 10px;
  padding: 24px 20px;
}

.blueCard {
  padding: 24px 20px;
  border-radius: 10px;
  border: 1px solid $primary-color;
  background-color: $primary-color-50;
}

.upcomingInnerBorder {
  border-bottom: 1px solid $primary-color-100;
}

.tab {
  width: 40%;
}

.popUpbutton {
  height: fit-content;
}

.webView {
  display: none;
}

.opacity {
  opacity: 0.4;
  position: relative;
}

.PopUp {
  position: absolute;
  border-radius: 10px;
  border: 1px solid grey;
  background: $white;
  padding: 12px;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.1);
  top: 300%;
}

.link {
  color: $primary-color;
  cursor: pointer;
}

.introTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.statusCard {
  border: 1px solid $gray-200-color;
  border-radius: 10px;
  padding: 24px 20px;
  width: 100%;
}

.alertBox {
  border-radius: 10px;
  padding: 24px 32px;
  border: 1px solid $danger-color;
  background: $danger-color-50;
}

.addBtn {
  color: $black !important;
  border: 1px solid $primary-color !important;
  width: 150px !important;
  height: 38px;
}

.btn {
  border-radius: 20px;
  padding: 4px 16px;
  font-size: 16px;
  font-weight: 600;
  color: $gray-color !important;
  border: 1px solid $primary-color-100;
  background: $white;
}

.outlinedBtn {
  width: fit-content !important;
  background-color: $primary-color-50 !important;
  color: $black !important;
  border: 2px solid $primary-color !important;
}

.tabWidth {
  width: 100% !important;
}

.tabJobs {
  width: 50% !important;
}

.borderClass {
  border-left: 1px solid $gray-200-color;
  border-right: 1px solid $gray-200-color;
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
}

.whiteBtn {
  background: none !important;
  color: $tertiary-color-500 !important;
  font-weight: 400;
  border: none !important;
  width: fit-content;
  height: 20px;
}

.screening {
  border-radius: 10px;
  background: $success-color-50;
  padding: 4px 16px;
}

.activeBtn {
  @extend .btn;
  border: 1px solid $primary-color;
  background: $primary-color-50;
}

.filter {
  border-top: 1px solid $gray-200-color;
  border-bottom: 1px solid $gray-200-color;
}
.contentDashboard {
  @extend .content;
  justify-content: space-between;
}
.mobileFilter {
  background: transparent !important;
  color: $tertiary-color-500 !important;
}

.hyperlinkStyle {
  color: $primary-color;
  cursor: pointer;
  text-decoration: underline;
}

.responsive {
  width: 100%;
}

@include respondTo($md) {
  .tabWidth {
    width: 120% !important;
  }
  .searchWidth {
    width: 370px;
  }
  .mobileFilter {
    display: none !important;
  }
  .filterBtn {
    display: flex;
  }
  .dashboardSearch {
    width: 24vw;
  }
  .responsive {
    display: flex;
  }
}

@include respondTo($lg) {
  .PopUp {
    width: 620px;
    left: 37%;
    top: 120%;
    padding: 24px;
  }
}

@include respondTo($xl) {
  .card {
    padding: 43px 32px;
  }
  .webView {
    display: block;
  }
  .mobileView {
    display: none;
  }
  .PopUp {
    left: 45%;
  }
  .yellowBox {
    padding: 50px 24px 24px 24px;
  }
  .widthBox {
    width: 25%;
  }
  .introTitle {
    flex-direction: row;
  }

  .content {
    padding: 70px;
    flex-direction: row;
  }
  .infoContent {
    display: flex;
    flex-direction: column;
    padding: 70px;
  }
  .contentCompany {
    padding: 70px 300px;
  }
  .leftSide {
    width: 30%;
    margin-right: 60px;
  }
  .leftSideDashboard {
    width: 30rem;
  }
  .rightContetSide {
    width: 64vw;
  }
  .rightSide {
    width: 70%;
  }
  .loadingOpacity {
    opacity: 0.5;
  }
}
