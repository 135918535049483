@import 'src/scss/settings';

.imageRound {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  border: 3px solid $white;
  margin-left: -10px;
}

.imageRoundDots {
  @extend .imageRound;
  background: $gray-200-color;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addTeam {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 2px solid $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $primary-color-50;
}

@include respondTo($md) {
  .justifyEnd {
    justify-content: end;
  }
}
